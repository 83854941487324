<template>
  <v-container>
    <v-stepper v-model="curr" color="green" class="main-grey-light pb-6">
      <v-stepper-header
        class="pt-10 pb-0 d-flex align-center justify-center border-none shadow-none"
      >
        <h6 class="mr-2 blue--text stepper-head-text">新規会員登録</h6>
        <v-stepper-step
          v-for="(step, n) in steps"
          :key="n"
          :complete="stepComplete(n + 1)"
          :step="`${n + 1}`"
          :rules="[value => !!step.valid]"
          :color="stepStatus(n + 1)"
          class="px-0 py-0 mr-1 stepper-step"
          :error-icon="n + 1"
        >
          <span v-if="n + 1 < lastStep">></span>
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-content v-for="(step, n) in steps" :step="n + 1" :key="n">
        <v-form :ref="'stepForm'" v-model="step.valid" lazy-validation>
          <!-- Step 1 -->
          <v-card-text
            class="pb-0"
            v-for="field in step.fields"
            :key="field.name"
          >
            <template slot="default">asd</template>
            <v-row v-if="field.type == 'text'">
              <v-col
                cols="12"
                :md="field.additional_field ? '6' : '12'"
                class="py-0 px-0"
              >
                <v-label>{{ field.label }}</v-label>
                <v-text-field
                  :placeholder="field.placeholder"
                  dense
                  solo
                  v-model="field.value"
                  :rules="field.rules"
                  :error-messages="errors"
                >
                </v-text-field>
              </v-col>
              <v-col
                v-if="field.additional_field"
                cols="12"
                :md="field.additional_field ? '6' : '12'"
                class="py-0 px-0 pl-3"
              >
                <div class="hide-visible">
                  <v-label>sr-only</v-label>
                </div>
                <v-text-field
                  dense
                  solo
                  :rules="field.rules"
                  v-model="field.additional_field.value"
                  :placeholder="field.additional_field.placeholder"
                  :error-messages="errors"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="field.type == 'dropdown'">
              <v-col
                cols="12"
                :md="field.additional_field ? '6' : '12'"
                class="py-0 px-0"
              >
                <v-label>{{ field.label }}</v-label>
                <v-select
                  :label="field.placeholder"
                  dense
                  solo
                  :items="field.listValues"
                  v-model="field.value"
                  :rules="field.rules"
                  :error-messages="errors"
                ></v-select>
              </v-col>
              <v-col
                v-if="field.additional_field"
                cols="12"
                :md="field.additional_field ? '6' : '12'"
                class="py-0 px-0 pl-3"
              >
                <div class="hide-visible">
                  <v-label>sr-only</v-label>
                </div>
                <v-select
                  dense
                  solo
                  :items="field.additional_field.listValues"
                  :rules="field.rules"
                  v-model="field.additional_field.value"
                  :label="field.additional_field.placeholder"
                  :error-messages="errors"
                ></v-select>
              </v-col>
            </v-row>

            <v-row v-if="field.type == 'image'">
              <v-col
                cols="12"
                :md="field.additional_field ? '6' : '12'"
                class="py-0 px-0"
              >
                <v-label>{{ field.label }}</v-label>
                <v-file-input
                  accept="image/*"
                  placeholder="Choose File"
                  class="prepend-icon-class"
                  dense
                  solo
                  prepend-icon="mdi-plus"
                  :error-messages="errors"
                  :rules="field.rules"
                  v-model="field.value"
                >
                </v-file-input>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
        <v-btn
          block
          v-if="n + 1 < lastStep"
          color="primary"
          class="stepper-btns"
          @click="validate(n)"
          :disabled="!step.valid"
          >次へ</v-btn
        >
        <v-btn
          v-else
          class="stepper-btns"
          block
          color="primary"
          @click="validate(n)"
          >登録完了</v-btn
        >
        <v-btn
          block
          class="mt-3"
          color="grey white--text"
          v-if="curr != 1"
          @click="curr = n"
          >戻る</v-btn
        >
      </v-stepper-content>
    </v-stepper>
  </v-container>
</template>

<script>
export default {
  name: "RegisterPage",
  data() {
    return {
      errors: "",
      formStatus: false,
      curr: 1,
      lastStep: 3,
      steps: [
        {
          name: "Start",
          fields: [
            {
              label: "会社名",
              name: "company_name",
              type: "text",
              placeholder: "クロスポイント株式会社",
              value: "",
              rules: [v => !!v || "This field is required"]
            },
            {
              label: "代表者名",
              name: "representative_name",
              type: "text",
              placeholder: "山田 太郎",
              value: "",
              rules: [v => !!v || "This field is required"]
            },
            {
              label: "都道府県",
              name: "prefecture",
              type: "text",
              placeholder: "東京都",
              value: "",
              rules: [v => !!v || "This field is required"]
            },
            {
              label: "市区町村番地",
              name: "address",
              type: "text",
              placeholder: "港区六本木西麻布1-12-5",
              value: "",
              rules: [v => !!v || "This field is required"]
            },
            {
              label: "電話番号",
              name: "phone",
              type: "text",
              placeholder: "03-5413-1800",
              value: "",
              rules: [v => !!v || "This field is required"]
            }
          ],
          valid: true
        },
        {
          name: "Step 2",
          fields: [
            {
              label: "事業形態",
              name: "business_form",
              type: "dropdown",
              placeholder: "個人事業主",
              value: "",
              listValues: ["Buz1", "Buz2"],
              rules: [v => !!v || "This field is required"]
            },
            {
              label: "事業開始年/月",
              name: "year",
              type: "dropdown",
              placeholder: "2021年",
              value: "",
              listValues: this.yearsList(),
              rules: [v => !!v || "This field is required"],
              additional_field: {
                label: "",
                name: "month",
                type: "dropdown",
                placeholder: "1月",
                value: "",
                listValues: this.monthDays(),
                rules: [v => !!v || "This field is required"]
              }
            },
            {
              label: "従業員数",
              name: "emp_number",
              type: "dropdown",
              placeholder: "2～5名",
              value: "",
              listValues: [1, 2, 3, 4, 5],
              rules: [v => !!v || "This field is required"]
            },
            {
              label: "お店の種類",
              name: "shop_type",
              type: "dropdown",
              placeholder: "中華料理",
              value: "",
              listValues: ["shop1", "shop2"],
              rules: [v => !!v || "This field is required"]
            },
            {
              label: "座席数",
              name: "no_of_seats",
              type: "dropdown",
              placeholder: "～30席",
              value: "",
              listValues: [1, 2, 3, 4, 5],
              rules: [v => !!v || "This field is required"]
            },
            {
              label: "登録写真",
              name: "image",
              type: "image",
              placeholder: "eee.png",
              value: null,
              rules: [v => !!v || "This field is required"]
            }
          ],
          valid: true
        },
        {
          name: "Complete",

          fields: [
            {
              label: "登録者名",
              name: "first_name",
              type: "text",
              placeholder: "姓",
              value: "",
              rules: [v => !!v || "This field is required"],
              additional_field: {
                label: "",
                name: "last_name",
                type: "text",
                placeholder: "名",
                value: "",
                rules: [v => !!v || "This field is required"]
              }
            },
            {
              label: "登録者名",
              name: "position",
              type: "text",
              placeholder: "エリアマネージャー",
              value: "",
              rules: [v => !!v || "This field is required"]
            },
            {
              label: "メールアドレス(ID)",
              name: "email",
              type: "text",
              placeholder: "mail@crosspoint.com",
              value: "",
              rules: [v => !!v || "This field is required"]
            },
            {
              label: "パスワード",
              name: "password",
              type: "text",
              placeholder: "6文字以上の英数字を設定してください",
              value: "",
              rules: [v => !!v || "This field is required"]
            },
            {
              label: "パスワード(確認用)",
              name: "confirmed_password",
              type: "text",
              placeholder: "もう一度入力してください",
              value: "",
              rules: [v => !!v || "This field is required"]
            },
            {
              label: "紹介コード",
              name: "referral_code",
              type: "text",
              placeholder: "紹介コードをお持ちの方のみ",
              value: "",
              rules: [v => !!v || "This field is required"]
            }
          ]
        }
      ],
      valid: false,
      stepForm: []
    };
  },
  methods: {
    stepComplete(step) {
      return this.curr > step;
    },
    stepStatus(step) {
      return this.curr > parseInt(step) ? "green" : "blue";
    },
    validate(n) {
      this.steps[n].valid = false;
      let v = this.$refs.stepForm[n].validate();
      if (v) {
        this.steps[n].valid = true;
        // continue to next
        this.curr = n + 2;
      }
      if (v && n + 1 > this.lastStep) {
        this.done();
      }
    },
    done() {
      this.curr = 5;
    },
    yearsList() {
      let year = [];
      let current = new Date("1980-01-01").getFullYear();
      let end = new Date().getFullYear();
      for (let i = current; i <= end; i++) {
        if (i < 10) i = `0${i}`;
        year.push({ value: i, text: `${i}年` });
      }
      return year;
    },
    monthDays() {
      let days = [];
      for (let i = 1; i <= 12; i++) {
        if (i < 10) i = `0${i}`;
        days.push({ value: i, text: `${i}月` });
      }
      return days;
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
