<template>
  <RegisterPage />
</template>

<script>
import RegisterPage from "@/components/layout/auth/RegisterPage";
export default {
  name: "Register",
  components: { RegisterPage }
};
</script>

<style scoped></style>
